import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SIBIndexPageOverview = (props) => {
  return (
    <section className="section bg-paper bg-tear-white">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <h2 className="heading-large">Making Shakespeare accessible</h2>
              <p>
                Shakespeare In Bits brings William Shakespeare's most widely
                studied plays to life with an immersive, digital approach.
                Each title is packed with features to support the study and
                teaching of the Bard's works like never before.
              </p>
              <p className="mb-0">Currently included in the series:</p>
              <ul className="text-list">
                <li>Romeo & Juliet</li>
                <li>Macbeth</li>
                <li>A Midsummer Night's Dream</li>
                <li>Julius Caesar</li>
                <li>Hamlet</li>
              </ul>
              <div className="panel-launch">
                <StaticImage
                  src="../../../scss/img/home-launch-demo.png"
                  alt="Try Now"
                />
                <Link className="button is-black" to="/signup">
                  Try Now
                </Link>
              </div>
              <p>
                Join over 30,000 students, teachers, and Shakespeare enthusiasts
                who are using Shakespeare In Bits in classrooms and homes across
                the US, Canada, Australia, the UK, Ireland, and beyond!
              </p>
              <p>
                <Link className="text-link" to="/pricing">
                  See Pricing
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SIBIndexPageOverview
