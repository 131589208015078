import React from "react"

import svgQuill from "../../../scss/img/icon-quill-orange.svg"
import svgDevices from "../../../scss/img/icon-devices-orange.svg"
import svgThumbUp from "../../../scss/img/icon-thumb-up-orange.svg"

import { Link } from "gatsby"

const SIBIndexPageEducators = (props) => {
  return (
    <section className="section pt-0 bg-tear-yellow">
      <div className="container">
        {/*<!-- Educators: Intro -->*/}
        <div className="columns mb-5">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <h2>An ideal resource for educators</h2>
              <p>
                Shakespeare In Bits can be used in many learning settings — in
                the classroom and at home — wherever works best for you and your
                students.
              </p>
            </div>
          </div>
        </div>
        {/*<!-- /Educators: Intro -->*/}

        {/*<!-- Educators: Benefits -->*/}
        <div className="columns is-desktop">
          <div className="column">
            <div className="box icon-box bg-paper">
              <img className="image" src={svgQuill} alt="Accessible and engaging" />
              <h3>Making Shakespeare’s plays accessible and engaging</h3>
              <p>
                Shakespeare In Bits was consciously designed to help students of
                diverse learning styles and abilities to engage with and
                appreciate the multifaceted richness of Shakespeare’s work.
                While presented so as to make the texts seem less daunting, all
                our titles are academically rigorous — featuring unabridged play
                text, voice-over audio from renowned Shakespearean actors, along
                with comprehensive notes and analysis.
              </p>
            </div>
          </div>
          <div className="column">
            <div className="box icon-box bg-paper">
              <img
                className="image"
                src={svgDevices}
                alt="Use in-class or remotely"
              />
              <h3>Use in a way that works for you — in-class or remotely</h3>
              <p>
                Shakespeare In Bits can be used by educators in-class as part of
                an organized lesson and by students — working individually or in
                groups. Students can also log in at home to access the plays
                using the same account, ensuring "access anywhere" to the
                materials being taught — ideal for remote learning and blended
                learning approaches.
              </p>
              <p>No hard copy textbook required!</p>
            </div>
          </div>
          <div className="column">
            <div className="box icon-box bg-paper">
              <img
                className="image"
                src={svgThumbUp}
                alt="Convenient and hassle-free"
              />
              <h3>A convenient, hassle-free way to learn online</h3>
              <p>
                Easy to access and deploy — no downloads, installations or IT
                involvement. All you and your students need is an internet
                connection, a standard browser and audio speakers or headphones
                to start using our digital renditions of the plays. Ideally
                suited to BYOD or mixed device environments, smoothing the path
                to incorporating digital learning into your literature program.
              </p>
            </div>
          </div>
        </div>
        {/*<!-- /Educators: Benefits -->*/}

        {/*<!-- Educators: Closing -->*/}
        <div className="columns mt-5">
          <div className="column is-8 is-offset-2 has-text-centered">
            <div className="content">
              <p className="heading-tagline">
                Trusted by teachers and parents worldwide
              </p>
              <p>
                <Link className="text-link" href="/educators">
                  More information for educators
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/*<!-- /Educators: Closing -->*/}
      </div>
    </section>
  )
}

export default SIBIndexPageEducators
