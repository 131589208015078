import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBIndexPageHero from "../components/frontend/indexpage/hero"
import SIBIndexPageOverview from "../components/frontend/indexpage/overview"
import SIBIndexPageFeatures from "../components/frontend/indexpage/features"
import SIBIndexPageEducators from "../components/frontend/indexpage/educators"

import SIBCommonTestimonials from "../components/frontend/common/testimonials"
import SIBCommonSignupCTA from "../components/frontend/common/signupcta"

const IndexPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Home" />
      <SIBIndexPageHero/>
      <SIBIndexPageOverview/>
      <SIBIndexPageFeatures/>
      <SIBIndexPageEducators/>
      <SIBCommonTestimonials/>
      <SIBCommonSignupCTA/>
    </Layout>
  )
}

IndexPage.propTypes = {
  site: PropTypes.shape({
    siteMetadata: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  }),
}
export default IndexPage

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
