import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const SIBIndexPageFeatures = (props) => {
  return (
    <section className="section">
      <div className="container">
        {/*<!-- Features: Block - Image Right -->*/}
        <div className="columns feature-block is-reversed-mobile">
          <div className="column">
            <div className="content">
              <p className="tagline">Language comprehension</p>
              <h3>Read between the lines</h3>
              <p>
                Appreciate the linguistic richness of William Shakespeare’s
                plays — the original, unabridged text is broken into easily
                digested ‘bits’, each featuring multiple aids to demystify the
                Bard's fertile prose. Understand difficult words and phrases in
                context, with our helpful in-line translations and explanatory
                margin notes.
              </p>
              <p>
                <Link className="text-link" to="/product">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
          <div className="column has-text-right-desktop">
            <StaticImage
              src="../../../scss/img/index-read-between.png"
              alt="Placeholder"
            />
          </div>
        </div>
        {/*<!-- /Features: Block - Image Right -->*/}

        {/*<!-- Features: Block - Image Left -->*/}
        <div className="columns feature-block">
          <div className="column">
            <StaticImage
              src="../../../scss/img/index-hear-it.png"
              alt="Placeholder"
            />
          </div>
          <div className="column">
            <div className="content">
              <p className="tagline">Engaging digital presentation</p>
              <h3>Hear it, see it</h3>
              <p>
                Visualize scenes and understand what's going on — each title
                includes hours of rich animation with full professional audio.
                The text — presented side-by-side with animated action — is easy
                to follow, with parts highlighted as they are spoken in the
                play. This integrated combination of text, audio, and graphics
                is uniquely designed to facilitate learners of diverse abilities
                and learning styles.
              </p>
              <p>
                <Link className="text-link" to="/product">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/*<!-- /Features: Block - Image Left -->*/}

        {/*</div><!-- Features: Block - Image Right -->*/}
        <div className="columns feature-block is-reversed-mobile">
          <div className="column">
            <div className="content">
              <p className="tagline">Play cast</p>
              <h3>Character building</h3>
              <p>
                Easily familiarize yourself with Shakespeare’s dramatis personae
                — bios for every character are accessible in context or through
                a dedicated Cast feature. Character relationship maps illustrate
                key characters and their interrelationships in each play,
                helping you quickly get up to speed with who's who.
              </p>
              <p>
                <Link className="text-link" to="/product">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
          <div className="column has-text-right-desktop">
            <StaticImage
              src="../../../scss/img/index-character-building.png"
              alt="Placeholder"
            />
          </div>
        </div>
        {/*<!-- Features: Block - Image Right -->*/}

        {/*<!-- Features: Block - Image Left --></div>)*/}
        <div className="columns feature-block">
          <div className="column">
            <StaticImage
              src="../../../scss/img/index-ace-that-exam.png"
              alt="Placeholder"
            />
          </div>
          <div className="column">
            <div className="content">
              <p className="tagline">Study notes</p>
              <h3>Ace that exam</h3>
              <p>
                Understand plots, key themes, language, imagery, and important
                quotes from the play — each Shakespeare In Bits title provides
                thorough, integrated analyses and study notes for each scene. An
                easy-to-navigate visual scene selection menu is also provided to
                help you to quickly get familiar with the play’s structure and
                sequence of events.
              </p>
              <p>
                <Link className="text-link" to="/product">
                  Learn More
                </Link>
              </p>
            </div>
          </div>
        </div>
        {/*<!-- /Features: Block - Image Left -->·*/}
        <div className="box link-box bg-blue">
          <p className="tagline">Now online</p>
          <p>
            Sample Shakespeare In Bits right now — the powerful resource for
            teaching and studying Shakespeare's most popular works!
          </p>
          <Link className="button is-black" to="/signup">
            Try Now
          </Link>
        </div>
      </div>
    </section>
  )
}

export default SIBIndexPageFeatures
