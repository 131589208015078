/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react"

import usePlayBannerImages from "../../hooks/useplaybannerimages"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby"

const SIBIndexPageHero = (props) => {
  const backgroundDivRef = useRef()
  const bannerImages = usePlayBannerImages()

  const [randomIndex, setRandomIndex] = useState(0)

  const { register, handleSubmit } = useForm({ reValidateMode: "onSubmit" })

  const heroBGDefs = [
    {
      bgImage: getImage(bannerImages.raj_banner_hero),
      heroClass: "hero-raj",
    },
    {
      bgImage: getImage(bannerImages.macbeth_banner_hero),
      heroClass: "hero-macbeth",
    },
    {
      bgImage: getImage(bannerImages.msnd_banner_hero),
      heroClass: "hero-msnd",
    },
    {
      bgImage: getImage(bannerImages.hamlet_banner_hero),
      heroClass: "hero-hamlet",
    },
    {
      bgImage: getImage(bannerImages.jc_banner_hero),
      heroClass: "hero-jc",
    },
  ]

  useEffect(() => {
    setRandomIndex(Math.floor(Math.random() * heroBGDefs.length))
    backgroundDivRef.current.classList = []
    backgroundDivRef.current.classList.add("hero")
    backgroundDivRef.current.classList.add(heroBGDefs[randomIndex].heroClass)
  })

  const onSubmit = async (data) => {
    navigate("/signup", { state: { email: data.email } })
  }

  return (
    <section className={`hero`} ref={backgroundDivRef}>
      <div className="hero-body">
        <div className="container">
          <div className="columns is-desktop">
            <div className="column is-7-desktop">
              <p className="tagline">Our popular resource is now online!</p>
              <h1>Shakespeare, as you like it.</h1>
              <p className="subtitle">
                Enjoy fully animated visuals, audio and the unabridged text in
                one comprehensive package.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field is-grouped">
                  <p className="control is-expanded">
                    <input
                      {...register("email", {})}
                      className="input"
                      type="text"
                      placeholder="Email"
                    />
                  </p>
                  <p className="control">
                    <button className="button is-black" type="submit">
                      Sign up &ndash; it's free!
                    </button>
                  </p>
                </div>
              </form>
            </div>
            <div className="column image-column">
              <GatsbyImage
                className="play-image"
                image={heroBGDefs[randomIndex].bgImage}
                alt="Shakespeare in Bits"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SIBIndexPageHero
